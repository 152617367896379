<template>
  <div class="mt-3">
    <ul class="nav nav-tabs justify-content-center" 
        id="myTab" 
        role="tablist">
      <li class="nav-item" 
          role="presentation">
        <button class="nav-link active" 
                id="actif-tab" 
                data-toggle="tab" 
                data-target="#actif" 
                type="button" 
                role="tab" 
                aria-controls="actif" 
                aria-selected="true">Actif</button>
      </li>
      <li class="nav-item" 
          role="presentation">
        <button class="nav-link" 
                id="passif-tab" 
                data-toggle="tab" 
                data-target="#passif" 
                type="button" 
                role="tab" 
                aria-controls="passif" 
                aria-selected="false">Passif</button>
      </li>
    </ul>
    <div class="tab-content" 
         id="myTabContent">
      <div class="tab-pane fade show active" 
           id="actif" 
           role="tabpanel" 
           aria-labelledby="actif-tab">
        <table class="table mt-3"
               style="font-size: 0.6em;">
          <thead>
            <tr>
              <th class="th-blue"
                  rowspan="2"
                  style="width:40%;vertical-align: middle;text-align: center;">ACTIF</th>
              <th class="th-blue text-center"
                  colspan="3">Exercice au {{ bilanData.annee_fin }}</th>
              <th class="th-blue text-center">Exercice de {{ annee_n1 }}</th>
            </tr>
            <tr>
              <th class="th-blue text-right">BRUT</th>
              <th class="th-blue text-right">AMORT et DEPREC.</th>
              <th class="th-blue text-center">NET</th>
              <th class="th-blue text-center">NET</th>
            </tr>
          </thead>
          <template v-for="(groupe,gkey) in total_groupe_actif">
            <tbody :key="gkey">
              <tr>
                <td scope="row"><strong>{{groupe.libelle}}</strong></td>
                <td scope="row"
                    class="text-right"><strong>{{(total_groupe_actif[gkey].brut_n).toLocaleString()}}</strong></td>
                <td scope="row"
                    class="text-right"><strong>{{(total_groupe_actif[gkey].amort_n).toLocaleString()}}</strong></td>
                <td scope="row"
                    class="text-right"><strong>{{(Number(total_groupe_actif[gkey].brut_n) - Number(total_groupe_actif[gkey].amort_n)).toLocaleString()}}</strong></td>
                <td scope="row"
                    class="text-right"><strong>{{(Number(total_groupe_actif[gkey].brut_n_1) - Number(total_groupe_actif[gkey].amort_n_1)).toLocaleString()}}</strong></td>
              </tr>
              <template v-for="(bilan,bilkey) in bilanValue">
                <tr :key="'sousgroupe'+bilkey"
                    v-if="groupe.libelle==bilan.etat_financier_groupes && bilan.preriode=='n'">
                  <td>{{bilan.libelle}}</td>
                  <td class="text-right"
                      v-if="bilan.statut_sous_groupe=='BRUTE' && bilan.preriode=='n'">
                    {{Math.round(Number(bilan.montant)).toLocaleString()}}
                  </td>
                  <td class="text-right"
                      v-else>0</td>
                  <td class="text-right"
                      v-if="bilan.statut_sous_groupe=='AMORTISSEMENT' && bilan.preriode=='n'">
                    {{Math.round(Number(bilan.montant)).toLocaleString()}}
                  </td>
                  <td class="text-right"
                      v-else>0</td>
                  <td class="text-right"
                      v-if="bilan.preriode=='n'">
                    {{Math.round(Number(bilan.montant)).toLocaleString()}}
                  </td>
                  <template v-for="(bilan_n1,biln1key) in bilanValue">
                    <td class="text-right"
                        :key="'annee_n-1'+biln1key"
                        v-if="bilan_n1.preriode=='n-1' && bilan_n1.libelle == bilan.libelle">
                      {{Math.round(Number(bilan_n1.montant)).toLocaleString()}}
                    </td>
                  </template>
                </tr>
              </template>
            </tbody>
          </template>
          <tfoot>
            <tr>
              <td class="th-blue">TOTAL ACTIF</td>
              <td class="text-right th-blue">{{total_actif.brut_n.toLocaleString()}}</td>
              <td class="text-right th-blue">{{total_actif.amort_n.toLocaleString()}}</td>
              <td class="text-right th-blue">{{(Number(total_actif.brut_n) - Number(total_actif.amort_n)).toLocaleString()}}</td>
              <td class="text-right th-blue">{{(Number(total_actif.brut_n_1) - Number(total_actif.amort_n_1)).toLocaleString()}}</td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="tab-pane fade" 
           id="passif" 
           role="tabpanel" 
           aria-labelledby="passif-tab">
        <table class="table mt-3"
               style="font-size: 0.6em;">
          <thead>
            <tr>
              <th class="th-blue"
                  rowspan="2"
                  style="width:40%;vertical-align: middle;text-align: center;">PASSIF</th>
              <th class="th-blue text-center">Exercice au {{ bilanData.annee_fin }}</th>
              <th class="th-blue text-center">Exercice de {{ annee_n1 }}</th>
            </tr>
            <tr>
              <th class="th-blue text-center">NET</th>
              <th class="th-blue text-center">NET</th>
            </tr>
          </thead>
          <template v-for="(groupe,gkey) in total_groupe_passif">
            <tbody :key="'passif'+gkey">
              <tr>
                <td scope="row"><strong>{{groupe.libelle}}</strong></td>
                <td scope="row"
                    class="text-right"><strong>{{(Number(total_groupe_passif[gkey].brut_n) - Number(total_groupe_passif[gkey].amort_n)).toLocaleString()}}</strong></td>
                <td scope="row"
                    class="text-right"><strong>{{(Number(total_groupe_passif[gkey].brut_n_1) - Number(total_groupe_passif[gkey].amort_n_1)).toLocaleString()}}</strong></td>
              </tr>
              <template v-for="(bilan,bilkey) in bilanValue">
                <tr :key="'sousgroupe'+bilkey"
                    v-if="groupe.libelle==bilan.etat_financier_groupes && bilan.preriode=='n'">
                  <td>{{bilan.libelle}}</td>
                  <td class="text-right">
                    {{Math.round(Number(bilan.montant)).toLocaleString()}}
                  </td>
                  <template v-for="(bilan_n1,biln1key) in bilanValue">
                    <td class="text-right"
                        :key="'annee_n-1'+biln1key"
                        v-if="bilan_n1.preriode=='n-1' && bilan_n1.libelle == bilan.libelle">
                      {{Math.round(Number(bilan_n1.montant)).toLocaleString()}}
                    </td>
                  </template>
                </tr>
              </template>
            </tbody>
          </template>
          <tfoot>
            <tr>
              <td class="th-blue">TOTAL PASSIF</td>
              <td class="text-right th-blue">{{(Number(total_passif.brut_n) - Number(total_passif.amort_n)).toLocaleString()}}</td>
              <td class="text-right th-blue">{{(Number(total_passif.brut_n_1) - Number(total_passif.amort_n_1)).toLocaleString()}}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    
    
    <!-- <tbody>
        <tr>
          <td scope="row" 
              colspan="2"><strong>ACTIFS IMMOBILISES</strong></td>
        </tr>
        <tr>
          <td>Immobilisations incorporelles</td>
          <td class="text-right" 
              v-if="bilanValue.immobilisation_incorporelle">
            {{Math.round(Number(bilanValue.immobilisation_incorporelle)).toLocaleString()}}
          </td>
          <td class="text-right"
              v-else>0</td>
        </tr>
        <tr>
          <td>Immobilisations corporelles</td>
          <td class="text-right"
              v-if="bilanValue.immobilisation_corporelle">
            {{Math.round(Number(bilanValue.immobilisation_corporelle)).toLocaleString()}}
          </td>
          <td class="text-right"
              v-else>0</td>
        </tr>
        <tr>
          <td>Immobilisations financières</td>
          <td class="text-right"
              v-if="bilanValue.immobilisation_financiere">
            {{Math.round(Number(bilanValue.immobilisation_financiere)).toLocaleString()}}
          </td>
          <td class="text-right"
              v-else>0</td>
        </tr>
        <tr>
          <td scope="row" 
              colspan="2"><strong>ACTIFS CIRCULANTS</strong></td>
        </tr>
        <tr>
          <td>Stocks</td>
          <td class="text-right"
              v-if="bilanValue.stock">
            {{Math.round(Number(bilanValue.stock)).toLocaleString()}}
          </td>
          <td class="text-right"
              v-else>0</td>
        </tr>
        <tr>
          <td>Créances</td>
          <td class="text-right"
              v-if="bilanValue.creance">{{Math.round(Number(bilanValue.creance)).toLocaleString()}}</td>
          <td class="text-right"
              v-else>0</td>
        </tr>
        <tr>
          <td>Disponibilités</td>
          <td class="text-right"
              v-if="bilanValue.disponibilite">
            {{Math.round(Number(bilanValue.disponibilite)).toLocaleString()}}
          </td>
          <td class="text-right"
              v-else>0</td>
        </tr>
        <tr class="tht-dark-blue">
          <td scope="row">TOTAL ACTIFS</td>
          <td class="text-right">{{Math.round(total_actif).toLocaleString()}}</td>
        </tr>
        <tr>
          <td scope="row" 
              colspan="2"
              class="text-right"></td>
        </tr>
        <tr>
          <td scope="row" 
              colspan="2"><strong>CAPITAUX PROPRES ET ASSIMILES</strong></td>
        </tr>
        <tr>
          <td>Capitaux propres</td>
          <td class="text-right"
              v-if="bilanValue.capitaux_propre">
            {{Math.round(Number(bilanValue.capitaux_propre)).toLocaleString()}}
          </td>
          <td class="text-right"
              v-else>0</td>
        </tr>
        <tr>
          <td scope="row" 
              colspan="2"><strong>PASSIFS CIRCULANTS</strong></td>
        </tr>
        <tr>
          <td>Dettes financières</td>
          <td class="text-right"
              v-if="bilanValue.dette_financiere">
            {{Math.round(Number(bilanValue.dette_financiere)).toLocaleString()}}
          </td>
          <td class="text-right"
              v-else>0</td>
        </tr>
        <tr>
          <td>Dettes d'exploitation</td>
          <td class="text-right"
              v-if="bilanValue.dette_exploitation">
            {{Math.round(Number(bilanValue.dette_exploitation)).toLocaleString()}}
          </td>
          <td class="text-right"
              v-else>0</td>
        </tr>
        <tr>
          <td>Dettes sur Immobilisations</td>
          <td class="text-right"
              v-if="bilanValue.dette_immobilisation">
            {{Math.round(Number(bilanValue.dette_immobilisation)).toLocaleString()}}
          </td>
          <td class="text-right"
              v-else>0</td>
        </tr>
        <tr>
          <td>Autres dettes</td>
          <td class="text-right"
              v-if="bilanValue.autre_dette">
            {{Math.round(Number(bilanValue.autre_dette)).toLocaleString()}}
          </td>
          <td class="text-right"
              v-else>0</td>
        </tr>
        <tr class="tht-dark-blue">
          <td scope="row">TOTAL PASSIFS</td>
          <td class="text-right">{{Math.round(total_passif).toLocaleString()}}</td>
        </tr>
      </tbody> --> 
  </div> 
</template>
<style>
 button.nav-link{
  font-size: 0.9em;
 }
</style>
<script>
import { mapActions,mapGetters,mapMutations } from "vuex"

export default ({
  name:"Bilan",
  props:["year"],
  data:()=>({
    bilanData:{
      annee_debut:"",
      annee_fin:"",
      base:""
    },
    annee_n1:"",
    bilanValue:"",
    total_actif:{n:0,n_1:0},
    total_passif:{brut_n:0,brut_n_1:0,amort_n_1:0,amort_n:0},
    total_groupe_actif:[],
    total_groupe_passif:[],
    groupes:[],
  }),
  watch:{
    bilan(){
      if (this.bilan.donnees[0]) {
        // console.log(this.bilan.donnees[0])
        this.bilanValue=this.bilan.donnees
        this.groupes=[]
        this.bilan.donnees.forEach(groupe => {
          var isIn=false
          for (let index = 0; index < this.groupes.length; index++) {
            if (this.groupes[index].groupe==groupe.etat_financier_groupes) {
              isIn=true
            }
          }
          if (!isIn) {
            this.groupes.push({groupe:groupe.etat_financier_groupes,type:groupe.statut_groupe})
          }
        })
        var actif = 0
        var passif = 0
        this.total_actif={brut_n:0,brut_n_1:0,amort_n_1:0,amort_n:0}
        this.total_passif={brut_n:0,brut_n_1:0,amort_n_1:0,amort_n:0}

        for(let index=0; index < this.groupes.length; index++){
          if (this.groupes[index].type=="ACTIF") {
            this.total_groupe_actif.push({libelle:this.groupes[index].groupe,brut_n:0,amort_n:0,brut_n_1:0,amort_n_1:0})
            this.bilan.donnees.forEach(bilan => {
              if (bilan.etat_financier_groupes==this.total_groupe_actif[actif].libelle && bilan.preriode=='n') {
                if (bilan.statut_sous_groupe=="BRUTE") {
                  this.total_groupe_actif[actif].brut_n += bilan.montant
                  this.total_actif.brut_n += bilan.montant
                }else{
                  this.total_groupe_actif[actif].amort_n += bilan.montant
                  this.total_actif.amort_n += bilan.montant
                }
              }else{
                if (bilan.etat_financier_groupes==this.total_groupe_actif[actif].libelle && bilan.preriode=='n-1') {
                  // console.log("in")
                  if (bilan.statut_sous_groupe=="BRUTE") {
                    this.total_groupe_actif[actif].brut_n_1 += bilan.montant
                    this.total_actif.brut_n_1 += bilan.montant
                  }else{
                    this.total_groupe_actif[actif].amort_n_1 += bilan.montant
                    this.total_actif.amort_n_1 += bilan.montant
                  }
                } 
              }
            })
            actif += 1
          }else{
            this.total_groupe_passif.push({libelle:this.groupes[index].groupe,brut_n:0,amort_n:0,brut_n_1:0,amort_n_1:0})
            this.bilan.donnees.forEach(bilan => {
              console.log(this.total_groupe_passif)

              if (bilan.etat_financier_groupes == this.total_groupe_passif[passif].libelle && bilan.preriode=='n') {
                if (bilan.statut_sous_groupe=="BRUTE") {
                  this.total_groupe_passif[passif].brut_n += bilan.montant
                  this.total_passif.brut_n += bilan.montant
                }else{
                  this.total_groupe_passif[passif].amort_n += bilan.montant
                  this.total_passif.amort_n += bilan.montant
                }
              }else{
                if (bilan.etat_financier_groupes==this.total_groupe_passif[passif].libelle && bilan.preriode=='n-1') {
                  if (bilan.statut_sous_groupe=="BRUTE") {
                    this.total_groupe_passif[passif].brut_n_1 += bilan.montant
                    this.total_passif.brut_n_1 += bilan.montant
                  }else{
                    this.total_groupe_passif[passif].amort_n_1 += bilan.montant
                    this.total_passif.amort_n_1 += bilan.montant
                  }
                } 
              }
            })
            passif += 1
          }
        }
        console.log(this.total_groupe_passif)
      }
    }
  },
  created(){
    this.bilanData.annee_debut = this.year.annee_debut
    this.bilanData.annee_fin = this.year.annee_fin
    var annee= this.year.annee_debut.split("-")
    this.annee_n1=Number(annee[0])-1
    this.bilanData.base= this.year.base
    this.loadBilan(this.bilanData)
  },
  computed:{
    ...mapGetters(["bilan","msgFailBilan"])
  },
  methods:{
    ...mapActions(["loadBilan",]),
    ...mapMutations(["setBilan","setFailBilan"]),
  },
})
</script>
