<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <th class="th-blue"
            style="width:20%">Compte</th>
        <th class="th-blue"
            style="width:60%">Libellé</th>
        <th class="th-blue text-right"
            style="width:20%">Montant</th>
      </tr>
    </thead>
    <tbody v-for="(sieges,peskey) in siege"
           :key="peskey">
      <tr >
        <td scope="row"
            colspan="4"
            class="tht-blue text-center">{{ sieges.titre }}</td>
      </tr>
      <tr v-for="(value,valkey) in sieges.values" 
          :key="valkey">
        <td scope="row">{{ value.numero_compte }}</td>
        <td>{{ value.libelle }}</td>
        <td class="text-right">{{ value.montant.toLocaleString() }}</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
// import { mapActions,mapGetters,mapMutations } from "vuex"

export default ({
  name:"Siege",
  props:["analytique"],
  data: ()=>({
    siege:[],
    load: false
  }),
  created(){
    this.load=false
    if (this.analytique) {
      this.analytique.forEach(element => {
        if (this.siege.length > 0) {
          if (!this.siege.includes(element.groupe)) {
            this.siege.push(element.groupe)
          }   
        }else{
          this.siege.push(element.groupe)
        }
      })
      for (let index = 0; index < this.siege.length; index++) {
        this.siege[index]= {titre:this.siege[index], values:[]}
      }
      this.siege.forEach(pes => {
        this.analytique.forEach(element => {
          if (pes.titre == element.groupe) {
            pes.values.push(element)
          }
        })
      })
      this.load = true
      console.log(this.siege)
    }
  },
  watch:{
    analytique(){
      this.load=false
      if (this.analytique) {
        this.analytique.forEach(element => {
          if (this.siege.length > 0) {
            if (!this.siege.includes(element.groupe)) {
              this.siege.push(element.groupe)
            }   
          }else{
            this.siege.push(element.groupe)
          }
        })
        for (let index = 0; index < this.siege.length; index++) {
          this.siege[index]= {titre:this.siege[index], values:[]}
        }
        this.siege.forEach(pes => {
          this.analytique.forEach(element => {
            if (pes.titre == element.groupe) {
              pes.values.push(element)
            }
          })
        })
        this.load = true
        console.log(this.siege)
      }
    }
  },
  computed:{

  },
  methods:{
    
  }
})
</script>
