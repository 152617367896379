<template>
  <div>
    <div class="row my-2">
      
    </div>
 
    <Peage v-if="comptes=='PEAGE'"
           :analytique="analytiqueData"/>
    <Pesage v-if="comptes=='PESAGE'"
            :analytique="analytiqueData"/>
    <Siege v-if="comptes=='SIEGE'"
           :analytique="analytiqueData"/>
    <Consolide v-if="comptes=='CONSOLIDEE'"
               :analytique="analytiqueData"/>
    
  </div>
</template>
<script>
import { mapActions,mapGetters,mapMutations } from "vuex"
import Peage from "./Correspondance/Peage.vue"
import Pesage from "./Correspondance/Pesage.vue"
import Siege from "./Correspondance/Siege.vue"
import Consolide from "./Correspondance/Consolide.vue"

export default ({
  name:"Correspondance",
  props:["compte","year"],
  components:{
    Peage,
    Pesage,
    Siege,
    Consolide
  },
  data: ()=>({
    SaisieOperationData:{
      type_saisie:"",
      type_comptabilite:"",
      mouvement:"",
      annee: new Date().getFullYear()
    },
    user: "",
    comptes:[],
    analytiqueData:"",
    loaded:false,
  }),
  created(){
    this.comptes=""
    this.loadAnalytique(this.year)
    setTimeout(
      function() {
        this.comptes = this.year.base
      }.bind(this),
      500
    )
  },
  watch:{
    analytique(){
      if (this.analytique) {
        this.analytiqueData = ""
        if (this.analytique.donnees.length >0) {
          this.analytiqueData = this.analytique.donnees
          this.setAnalytique("")
        }
      }
    }
  },
  computed:{
    ...mapGetters(["analytique","msgFailAnalytique"])
  },
  methods:{
    ...mapActions(["loadAnalytique"]),
    ...mapMutations(["setAnalytique","setFailAnalytique"]),
  }
})
</script>
