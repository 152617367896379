<template>
  <div>
    <div class="row my-4">
      <form class="form-inline mx-auto">
        <div class="form-group">
          <label for="">Classe: </label>
          <select class="form-control mx-2" 
                  name="" 
                  id=""
                  v-model="JournalData.classe">
            <option value="1">Classe 1</option>
            <option value="2">Classe 2</option>
            <option value="3">Classe 3</option>
            <option value="4">Classe 4</option>
            <option value="5">Classe 5</option>
            <option value="6">Classe 6</option>
            <option value="7">Classe 7</option>
            <option value="8">Classe 8</option>
            <option value="">Tout</option>
          </select>
        </div>
        <!-- <div class="form-group">
          <label for="">Année: </label>
          <select class="form-control mx-2" 
                  name="" 
                  id=""
                  v-model="JournalData.annee">
            <option :value="JournalData.annee - 5">{{ JournalData.annee - 5 }}</option>
            <option :value="JournalData.annee - 4">{{ JournalData.annee - 4 }}</option>
            <option :value="JournalData.annee - 3">{{ JournalData.annee - 3 }}</option>
            <option :value="JournalData.annee - 2">{{ JournalData.annee - 2 }}</option>
            <option :value="JournalData.annee - 1">{{ JournalData.annee - 1 }}</option>
            <option :value="JournalData.annee">{{ JournalData.annee }}</option>
            <option :value="JournalData.annee + 1">{{ JournalData.annee + 1 }}</option>
            <option :value="JournalData.annee + 2">{{ JournalData.annee + 2 }}</option>
            <option :value="JournalData.annee + 3">{{ JournalData.annee + 3 }}</option>
            <option :value="JournalData.annee + 4">{{ JournalData.annee + 4 }}</option>
            <option :value="JournalData.annee + 5">{{ JournalData.annee + 5 }}</option>
          </select>
        </div> -->
        <!-- <div class="form-group">
          <label for="">Type de comptabilité: </label>
          <select class="form-control mx-2" 
                  name="" 
                  id=""
                  v-model="JournalData.type_comptabilite">
            <option value="CONSOLIDE">Consolidé</option>
            <option value="CONCESSION_PEAGE">Péage</option>
            <option value="PESAGE">Pesage</option>
            <option value="SIEGE">Siège</option>
            <option value="">Tout</option>
          </select>
        </div> -->
        <button type="button" 
                class="btn btn-primary"
                @click="search()">Rechercher</button>
      </form>
      <div class="div_left">
        <button class="btn btn-blue pl-5 pr-5 op-btn mr-4"
                @click="downloadExport(comptes.url)"
                data-target="#filtreModal"
                download>Exporter</button>
      </div>
      <div class="div_left">
        <button class="btn btn-blue pl-5 pr-5 op-btn"
                data-toggle="modal"
                ref="modal_button"
                data-target="#filtreModal">Filtrer</button>
      </div>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th class="th-blue">Compte</th>
          <th class="th-blue"
              style="width:55%">Libellé</th>
          <th class="th-blue text-right">Débit</th>
          <th class="th-blue text-right">Crédit</th>
          <th class="th-blue text-right"
              style="width:10%">Date</th>
        </tr>
      </thead>
      <tbody v-if="!loaded">
        <tr>
          <td scope="row"
              class="text-center"
              colspan="5">Chargement en cours...</td>
        </tr>
      </tbody>
      <tbody v-else-if="loaded && comptes.donnees.length > 0">
        <tr v-for="(compte,compkey) in comptes.donnees"
            :key="compkey">
          <td v-if="compte.compte!=null">{{compte.compte}}</td>
          <td v-else
              class="txt-blue">999999999</td>
          <td>{{compte.libelle}}</td>
          <td class="text-right">{{Math.round(Number(compte.debit)).toLocaleString()}}</td>
          <td class="text-right">{{Math.round(Number(compte.credit)).toLocaleString()}}</td>
          <td class="text-right">{{compte.date_creation}}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td scope="row"
              class="text-center"
              colspan="5">Aucune donnée enregistrée.</td>
        </tr>
      </tbody>
    </table>
    <div class="row pagination">
      <div v-if="comptes.pagination"
           class="col-md-2 mx-auto text-center">
        <span v-if="comptes.pagination.precedent"
              class="badge badge-pill badge-info"
              @click="pIndex--, paging(comptes.pagination.precedent)"> &#60; </span>
        {{ pIndex }}
        <span v-if="comptes.pagination.suivant"
              class="badge badge-pill badge-info"
              @click="pIndex++, paging(comptes.pagination.suivant)"> &#62; </span>
      </div>
    </div>
    <!-- Modal filtre-->
    <div class="modal fade col-md-12"
         id="filtreModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="filtreModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="filtreModalLabel">Paramètres de filtre</h5>
            <button type="button"
                    class="close"
                    ref="btn-close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ListFilter :champs="chmp"/>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-primary"
                    @click="filtrer(type)">filtrer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
#op{
  border: none;
  font-size: 0.7em;
  height: 5vh;
  background: none;
}
</style>
<script>
import { mapActions,mapGetters,mapMutations } from "vuex"
import ListFilter from "@/components/shared/Filter"

export default({
  name:"PlanComptable",
  props:["compte","year"],
  components:{
    ListFilter,
  },
  data: ()=>({
    JournalData:{
      // type_comptabilite:"",
      annee_debut: new Date().getFullYear()+"-01-01",
      annee_fin: new Date().getFullYear()+"-12-31",
      classe:"",
      base:"",
      filtre:""
    },
    comptes:[],
    loaded:false,
    pIndex:1,
    filtre:[],
    chmp:[
      {
        valeur:"compte",
        type:"caractere",
        table:"plan_comptes",
        libelle:"Compte",
      },
      {
        valeur:"libelle",
        type:"caractere",
        table:"comptabilite_globales",
        libelle:"Libellé",
      },
      {
        valeur:"date",
        type:"date",
        table:"comptabilite_globales",
        libelle:"Date",
      },
      {
        valeur:"debit",
        type:"numeric",
        table:"comptabilite_globales",
        libelle:"Débit",
      },
      {
        valeur:"credit",
        type:"numeric",
        table:"comptabilite_globales",
        libelle:"Crédit",
      },
    ],
    goodUrl:""
  }),
  created(){
    this.JournalData.annee_debut = this.year.annee_debut  
    this.JournalData.annee_fin = this.year.annee_fin  
    this.JournalData.base = this.year.base 
    this.loadJournal(this.JournalData)
  },
  watch:{
    journal(){
      if (this.journal) {
        this.loaded=true
        this.comptes = this.journal
        this.$refs["btn-close"].click()
        this.comptes.donnees.forEach(journal => {
          if (journal.date_creation.includes("T")) {
            var spliter = journal.date_creation.split("T")
            journal.date_creation = spliter[0]
          }
        })
        
        this.setJournal("")
      }
    },
    compte(){
      if (this.compte==true) {
        this.loaded=false
        this.loadJournal(this.JournalData)    
      }
    }
  },
  computed:{
    ...mapGetters(["journal","errorPlanMontant"])
  },
  methods:{
    ...mapActions(["loadJournal","pageJournal"]),
    ...mapMutations(["setJournal","setErrorPlanMontant"]),
    search(){
      this.loaded=false
      this.JournalData.annee_debut = this.year.annee_debut 
      this.JournalData.annee_fin = this.year.annee_fin 
      this.loadJournal(this.JournalData)
    },
    filtrer() {
      this.loaded = false
      this.long = null
      this.JournalData.filtre=this.filtre
      this.loadJournal(this.JournalData)
    },
    paging(url) {
      if (this.pIndex == 0) {
        this.pIndex = 1
      }
      this.goodUrl = url.split("/")
      url = this.goodUrl[1]+"&annee_debut="+this.JournalData.annee_debut+"&annee_fin="+this.JournalData.annee_fin+"&base="+this.JournalData.base
      this.loaded = false
      var count=1
      var used=0
      url+="&filtres=["  
      this.filtre.forEach(filtre => {
        if (filtre.type == "date") {
          if (filtre.operation!="" && filtre.valeur!="") {
            if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
              if (count>1 && used !=0) {
                url+=","
              }
              url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
                +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
              used+=1
            }else{
              if (count>1 && used !=0) {
                url+=","
              }
              url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
              used+=1
            }
          }
        }else{
          if (filtre.valeur!="" && filtre.nature!="") {
            if (count>1 && used !=0) {
              url+=","
              console.log(count)
            }
            url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
            used+=1
          }
        }
        count+=1
      })
      url+="]"
      this.setJournal("")
      this.pageJournal(url)
    },
    downloadExport(file){
      const link = document.createElement('a')
      link.href = process.env.VUE_APP_UPLOAD+file
      link.download = file
      link.click()
      URL.revokeObjectURL(link.href)
    },
  }
})
</script>
