<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <th class="th-blue"
            style="width:20%">Compte</th>
        <th class="th-blue"
            style="width:60%">Libellé</th>
        <th class="th-blue text-right"
            style="width:20%">Montant</th>
      </tr>
    </thead>
    <tbody v-for="(consolides,conskey) in consolide"
           :key="conskey">
      <tr >
        <td scope="row"
            colspan="4"
            class="tht-blue text-center">{{ consolides.titre }}</td>
      </tr>
      <tr v-for="(value,valkey) in consolides.values" 
          :key="valkey">
        <td scope="row">{{ value.numero_compte }}</td>
        <td>{{ value.libelle }}</td>
        <td class="text-right">{{ value.montant.toLocaleString() }}</td>
      </tr>
    </tbody>
    
  </table>
</template>
<script>
// import { mapActions,mapGetters,mapMutations } from "vuex"

export default ({
  name:"Consolide",
  props:["analytique"],
  data: ()=>({
    pesage:[],
    consolide:[],
    load: false
  }),
  created(){
    this.load=false
    if (this.analytique) {
      this.analytique.forEach(element => {
        if (this.pesage.length > 0) {
          if (!this.pesage.includes(element.groupe)) {
            this.pesage.push(element.groupe)
          }   
        }else{
          this.pesage.push(element.groupe)
        }
        if (this.consolide.length > 0) {
          if (!this.consolide.includes(element.groupe)) {
            if ((element.groupe =="CHARGES DE FONCTIONNEMENT SIEGE" || element.groupe =="CHARGES DE  FONCTIONNEMENT SIEGE") || element.groupe =="CHARGES DE FONCTIONNEMENT PEAGE") {
              if(!this.consolide.includes("CHARGES DE FONCTIONNEMENT")){
                this.consolide.push("CHARGES DE FONCTIONNEMENT")
              }
            }else{
              if (element.groupe =="DEPENSES DE PESAGE" || element.groupe =="DEPENSES DE PEAGE") {
                if(!this.consolide.includes("DEPENSES")){
                  this.consolide.push("DEPENSES")
                }
              }else{
                this.consolide.push(element.groupe)
              }
            }
          }
        }else{
          if ((element.groupe =="CHARGES DE FONCTIONNEMENT SIEGE" || element.groupe =="CHARGES DE  FONCTIONNEMENT SIEGE") || element.groupe =="CHARGES DE FONCTIONNEMENT PEAGE") {
            this.consolide.push("CHARGES DE FONCTIONNEMENT")
          }else{
            if (element.groupe =="DEPENSES DE PESAGE" || element.groupe =="DEPENSES DE PEAGE") {
              this.consolide.push("DEPENSES")
            }else{
              this.consolide.push(element.groupe)
            }
          }
        }
      })
      for (let index = 0; index < this.pesage.length; index++) {
        this.pesage[index]= {titre:this.pesage[index], values:[]}
      }
      for (let index = 0; index < this.consolide.length; index++) {
        this.consolide[index]= {titre:this.consolide[index], values:[]}
      }
      this.pesage.forEach(pes => {
        this.analytique.forEach(element => {
          if (pes.titre == element.groupe) {
            pes.values.push(element)
          }
        })
      })

      this.consolide.forEach(cons => {
        this.pesage.forEach(pes => {
          if (cons.titre == pes.titre) {
            cons.values=pes.values
          }else{
            if (cons.titre=="CHARGES DE FONCTIONNEMENT" && ((pes.titre =="CHARGES DE FONCTIONNEMENT SIEGE" || pes.titre =="CHARGES DE  FONCTIONNEMENT SIEGE") || pes.titre =="CHARGES DE FONCTIONNEMENT PEAGE")) {
              for (let index = 0; index < pes.values.length; index++) {
                cons.values.push(pes.values[index])  
              }
            }else{
              if (cons.titre=="DEPENSES" && (pes.titre =="DEPENSES DE PESAGE" || pes.titre =="DEPENSES DE PEAGE")) {
                for (let index = 0; index < pes.values.length; index++) {
                  cons.values.push(pes.values[index])  
                }
              } 
            }
          }
        })
      })

      this.load = true
      console.log(this.consolide)
    }
  },
  watch:{
    analytique(){
      this.load = false
      if (this.analytique) {
        this.analytique.forEach(element => {
          if (this.pesage.length > 0) {
            if (!this.pesage.includes(element.groupe)) {
              this.pesage.push(element.groupe)
            }   
          }else{
            this.pesage.push(element.groupe)
          }
          if (this.consolide.length > 0) {
            if (!this.consolide.includes(element.groupe)) {
              if ((element.groupe =="CHARGES DE FONCTIONNEMENT SIEGE" || element.groupe =="CHARGES DE  FONCTIONNEMENT   SIEGE") || element.groupe =="CHARGES DE FONCTIONNEMENT PEAGE") {
                if(!this.consolide.includes("CHARGES DE FONCTIONNEMENT")){
                  this.consolide.push("CHARGES DE FONCTIONNEMENT")
                }
              }else{
                if (element.groupe =="DEPENSES DE PESAGE" || element.groupe =="DEPENSES DE PEAGE") {
                  if(!this.consolide.includes("DEPENSES")){
                    this.consolide.push("DEPENSES")
                  }
                }else{
                  this.consolide.push(element.groupe)
                }
              }
            }
          }else{
            if ((element.groupe =="CHARGES DE FONCTIONNEMENT SIEGE" || element.groupe =="CHARGES DE  FONCTIONNEMENT   SIEGE") || element.groupe =="CHARGES DE FONCTIONNEMENT PEAGE") {
              this.consolide.push("CHARGES DE FONCTIONNEMENT")
            }else{
              if (element.groupe =="DEPENSES DE PESAGE" || element.groupe =="DEPENSES DE PEAGE") {
                this.consolide.push("DEPENSES")
              }else{
                this.consolide.push(element.groupe)
              }
            }
          }
        })
        for (let index = 0; index < this.pesage.length; index++) {
          this.pesage[index]= {titre:this.pesage[index], values:[]}
        }
        for (let index = 0; index < this.consolide.length; index++) {
          this.consolide[index]= {titre:this.consolide[index], values:[]}
        }
        this.pesage.forEach(pes => {
          this.analytique.forEach(element => {
            if (pes.titre == element.groupe) {
              pes.values.push(element)
            }
          })
        })
      
        this.consolide.forEach(cons => {
          this.pesage.forEach(pes => {
            if (cons.titre == pes.titre) {
              cons.values=pes.values
            }else{
              if (cons.titre=="CHARGES DE FONCTIONNEMENT" && ((pes.titre =="CHARGES DE FONCTIONNEMENT SIEGE" || pes.  titre =="CHARGES DE  FONCTIONNEMENT SIEGE") || pes.titre =="CHARGES DE FONCTIONNEMENT PEAGE")) {
                for (let index = 0; index < pes.values.length; index++) {
                  cons.values.push(pes.values[index])  
                }
              }else{
                if (cons.titre=="DEPENSES" && (pes.titre =="DEPENSES DE PESAGE" || pes.titre =="DEPENSES DE PEAGE")) {
                  for (let index = 0; index < pes.values.length; index++) {
                    cons.values.push(pes.values[index])  
                  }
                } 
              }
            }
          })
        })
      
        this.load = true
        console.log(this.consolide)
      }
    }
  },
  computed:{

  },
  methods:{
    
  }
})
</script>
