<template>
  <div>
    <div class="row my-4">
      <form class="form-inline mx-auto">
        <div class="form-group">
          <label for="">Nombre de chiffres: </label>
          <select class="form-control ml-2 mr-4" 
                  name="" 
                  id=""
                  v-model="balanceData.nombre_chiffre"
                  @change="search()">
            <option value=1>Classes</option>
            <option value=2>Sous-classes</option>
            <option value=3>Comptes généraux</option>
            <option value=8>Sous-comptes</option>
          </select>
        </div>
        <div class="form-group">
          <label for="">Classe: </label>
          <select class="form-control ml-2 mr-4" 
                  name="" 
                  id=""
                  v-model="classCompte"
                  @change="searchClass()">
            <option value="">Tout</option>
            <option value=1>1</option>
            <option value=2>2</option>
            <option value=3>3</option>
            <option value=4>4</option>
            <option value=5>5</option>
            <option value=6>6</option>
            <option value=7>7</option>
            <option value=8>8</option>
          </select>
        </div>
        <!-- <div class="form-group">
          <label for="">Période du: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="balanceData.annee_debut">
        </div>
        <div class="form-group">
          <label for="">au: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="balanceData.annee_fin">
        </div> -->
        <!-- <div class="form-group">
          <label for="">Année: </label>
          <select class="form-control mx-2" 
                  name="" 
                  id=""
                  v-model="JournalData.annee">
            <option :value="JournalData.annee - 5">{{ JournalData.annee - 5 }}</option>
            <option :value="JournalData.annee - 4">{{ JournalData.annee - 4 }}</option>
            <option :value="JournalData.annee - 3">{{ JournalData.annee - 3 }}</option>
            <option :value="JournalData.annee - 2">{{ JournalData.annee - 2 }}</option>
            <option :value="JournalData.annee - 1">{{ JournalData.annee - 1 }}</option>
            <option :value="JournalData.annee">{{ JournalData.annee }}</option>
            <option :value="JournalData.annee + 1">{{ JournalData.annee + 1 }}</option>
            <option :value="JournalData.annee + 2">{{ JournalData.annee + 2 }}</option>
            <option :value="JournalData.annee + 3">{{ JournalData.annee + 3 }}</option>
            <option :value="JournalData.annee + 4">{{ JournalData.annee + 4 }}</option>
            <option :value="JournalData.annee + 5">{{ JournalData.annee + 5 }}</option>
          </select>
        </div> -->
        <!-- <div class="form-group">
          <label for="">Type de comptabilité: </label>
          <select class="form-control mx-2" 
                  name="" 
                  id=""
                  v-model="JournalData.type_comptabilite">
            <option value="CONSOLIDE">Consolidé</option>
            <option value="CONCESSION_PEAGE">Péage</option>
            <option value="PESAGE">Pesage</option>
            <option value="SIEGE">Siège</option>
            <option value="">Tout</option>
          </select>
        </div> -->
        <!-- <button type="button" 
                class="btn btn-primary"
                @click="search()">Rechercher</button> -->
      </form>
      <div class="col-md-2">
        <button class="btn btn-round btn-blue"
                @click="generateReport()"> Imprimer </button>
      </div>
    </div>
    <!-- ///////////////////////////////////////////////////////////////////////// ZONE IMPRIMABLE ///////////////////////////////////////////////////////////////////////////////////////// -->
    <!-- Vue-html2pdf -->
    <vue-html2pdf :show-layout="false"
                  :float-layout="true"
                  :enable-download="true"
                  :preview-modal="true"
                  :paginate-elements-by-height="1300"
                  :filename="'Balance_'+year.base+'_du_'+year.annee_debut+'_au_'+year.annee_fin"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="portrait"
                  pdf-content-width="800px"

                  @progress="onProgress($event)"
                  @hasStartedGeneration="hasStartedGeneration()"
                  @hasGenerated="hasGenerated($event)"
                  ref="html2Pdf">
      <div slot="pdf-content"
           v-if="loaded==true">
        <div style="width: 95%;margin: auto;"
             class="pdf-item">
          <div class="row mt-2">
            <div class="col-3 ml-4">
              <img src="../../../assets/img/logo.svg" />
            </div>
            <div class="col-6">
            </div>
            <div class="col-2 ml-4">
            </div>
          </div>
          <div class="row justify-content-center my-2">
            <div class="col-8">
              <div class="card title-card">
                <div class="card-body text-center title">
                  Balance ({{ year.base }}) du {{ year.annee_debut }} au {{ year.annee_fin }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 95%;margin: auto;"
             class="pdf-item">
          <template v-for="(page,pakey) in balancePrint">
            <div class="pdf-item"
                 style="width: 100%;"
                 :key="'page '+pakey">
              <table class="table table-striped"
                     style="width: 100%;font-size: 0.65em;">
                <thead v-if="pakey < balancePrint.length">
                  <tr>
                    <th class="th-blue"
                        style="width: 6%;">Compte</th>
                    <th class="th-blue"
                        style="width: 22%;padding-left: 0%;">Libellé</th>
                    <th class="th-blue text-right"
                        style="width: 9%;">A Nouveau débiteur</th>
                    <th class="th-blue text-right"
                        style="width: 9%;">A Nouveau créditeur</th>
                    <th class="th-blue text-right"
                        style="width: 9%;">Mouvement débit</th>
                    <th class="th-blue text-right"
                        style="width: 9%;">Mouvement crédit</th>
                    <th class="th-blue text-right"
                        style="width: 9%;">Total débit</th>
                    <th class="th-blue text-right"
                        style="width: 9%;">Total crédit</th>
                    <th class="th-blue text-right"
                        style="width: 9%;">Solde débit</th>
                    <th class="th-blue text-right"
                        style="width: 9%;">Solde crédit</th>
                  </tr>
                </thead>
                <template v-for="(compte,cokey) in page">
                  <tbody :key="'compte '+cokey">
                    <tr>
                      <td scope="row">{{compte.numero_compte}}</td>
                      <td>{{compte.libelle}}</td>
                      <td class="text-right">{{Math.round(Number(compte.a_nouveau_debit)).toLocaleString()}}</td>
                      <td class="text-right">{{Math.round(Number(compte.a_nouveau_credit)).toLocaleString()}}</td>
                      <td class="text-right">{{Math.round(Number(compte.mouvement_debit)).toLocaleString()}}</td>
                      <td class="text-right">{{Math.round(Number(compte.mouvement_credit)).toLocaleString()}}</td>
                      <td class="text-right">{{Math.round(Number(compte.total_debit)).toLocaleString()}}</td>
                      <td class="text-right">{{Math.round(Number(compte.total_credit)).toLocaleString()}}</td>
                      <td class="text-right">{{Math.round(Number(compte.solde_debit)).toLocaleString()}}</td>
                      <td class="text-right">{{Math.round(Number(compte.solde_credit)).toLocaleString()}}</td>
                    </tr>
                  </tbody>
                </template>
              </table>
            </div>
            <table class="table table-striped"
                   style="width: 100%;font-size: 0.65em;"
                   :key="'total'+pakey"
                   v-if="pakey==(balancePrint.length-1)">
              <tbody>
                <tr>
                  <td scope="row"
                      colspan="2"
                      style="width: 29%;"
                      class="tht-blue">Total</td>
                  <td class="text-right tht-blue"
                      style="width: 9%;">{{Math.round(Number(totalBalance.a_nouveau_debit)).toLocaleString()}}</td>
                  <td class="text-right tht-blue"
                      style="width: 9%;">{{Math.round(Number(totalBalance.a_nouveau_credit)).toLocaleString()}}</td>
                  <td class="text-right tht-blue"
                      style="width: 9%;">{{Math.round(Number(totalBalance.mouvement_debit)).toLocaleString()}}</td>
                  <td class="text-right tht-blue"
                      style="width: 9%;">{{Math.round(Number(totalBalance.mouvement_credit)).toLocaleString()}}</td>
                  <td class="text-right tht-blue"
                      style="width: 9%;">{{Math.round(Number(totalBalance.total_debit)).toLocaleString()}}</td>
                  <td class="text-right tht-blue"
                      style="width: 9%;">{{Math.round(Number(totalBalance.total_credit)).toLocaleString()}}</td>
                  <td class="text-right tht-blue"
                      style="width: 9%;">{{Math.round(Number(totalBalance.solde_debit)).toLocaleString()}}</td>
                  <td class="text-right tht-blue"
                      style="width: 9%;">{{Math.round(Number(totalBalance.solde_credit)).toLocaleString()}}</td>
                </tr>
              </tbody>
            </table>
            <div class="html2pdf__page-break"
                 :key="'numero_page'+pakey"
                 v-if="balancePrint.length>1 && pakey!=balancePrint.length-1"/>
          </template>
          
        </div>
      </div>
    </vue-html2pdf>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
    <table class="table table-striped">
      <thead>
        <tr>
          <th class="th-blue">Compte</th>
          <th class="th-blue">Libellé</th>
          <th class="th-blue text-right">A Nouveau débiteur</th>
          <th class="th-blue text-right">A Nouveau créditeur</th>
          <th class="th-blue text-right">Mouvement débit</th>
          <th class="th-blue text-right">Mouvement crédit</th>
          <th class="th-blue text-right">Total débit</th>
          <th class="th-blue text-right">Total crédit</th>
          <th class="th-blue text-right">Solde débit</th>
          <th class="th-blue text-right">Solde crédit</th>
        </tr>
      </thead>
      <tbody v-if="loaded && long ==0">
        <tr>
          <td scope="row"
              colspan="10"
              class="text-center">Aucune donnée enregistrée...</td>
        </tr>
      </tbody>
      <tbody v-else-if="loaded && long > 0">
        <tr v-for="(ligne,balkey) in balanceComptable"
            :key="balkey">
          <td scope="row">{{ligne.numero_compte}}</td>
          <td>{{ligne.libelle}}</td>
          <td class="text-right">{{Math.round(Number(ligne.a_nouveau_debit)).toLocaleString()}}</td>
          <td class="text-right">{{Math.round(Number(ligne.a_nouveau_credit)).toLocaleString()}}</td>
          <td class="text-right">{{Math.round(Number(ligne.mouvement_debit)).toLocaleString()}}</td>
          <td class="text-right">{{Math.round(Number(ligne.mouvement_credit)).toLocaleString()}}</td>
          <td class="text-right">{{Math.round(Number(ligne.total_debit)).toLocaleString()}}</td>
          <td class="text-right">{{Math.round(Number(ligne.total_credit)).toLocaleString()}}</td>
          <td class="text-right">{{Math.round(Number(ligne.solde_debit)).toLocaleString()}}</td>
          <td class="text-right">{{Math.round(Number(ligne.solde_credit)).toLocaleString()}}</td>
        </tr>
        <tr>
          <td scope="row"
              colspan="2"
              class="tht-blue">Total</td>
          <td class="text-right tht-blue">{{Math.round(Number(totalBalance.a_nouveau_debit)).toLocaleString()}}</td>
          <td class="text-right tht-blue">{{Math.round(Number(totalBalance.a_nouveau_credit)).toLocaleString()}}</td>
          <td class="text-right tht-blue">{{Math.round(Number(totalBalance.mouvement_debit)).toLocaleString()}}</td>
          <td class="text-right tht-blue">{{Math.round(Number(totalBalance.mouvement_credit)).toLocaleString()}}</td>
          <td class="text-right tht-blue">{{Math.round(Number(totalBalance.total_debit)).toLocaleString()}}</td>
          <td class="text-right tht-blue">{{Math.round(Number(totalBalance.total_credit)).toLocaleString()}}</td>
          <td class="text-right tht-blue">{{Math.round(Number(totalBalance.solde_debit)).toLocaleString()}}</td>
          <td class="text-right tht-blue">{{Math.round(Number(totalBalance.solde_credit)).toLocaleString()}}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td scope="row"
              colspan="10"
              class="text-center">Chargement des données...</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VueHtml2pdf from 'vue-html2pdf'

export default ({
  name:"Balance",
  props:["year"],
  components: {
    VueHtml2pdf
  },
  data:()=>({
    balanceData:{
      // type_comptabilite:"",
      annee_debut: new Date().getFullYear()+"-01-01",
      annee_fin: new Date().getFullYear()+"-01-01",
      nombre_chiffre:1,
      base:"",
      filtre:"",
      consultation:""
    },
    totalBalance:{
      a_nouveau_debit:0,
      a_nouveau_credit:0,
      mouvement_debit:0,
      mouvement_credit:0,
      total_debit:0,
      total_credit:0,
      solde_debit:0,
      solde_credit:0,
    },
    classCompte:"",
    balanceComptable:[],
    balancePrint:[],
    long:0,
    nb_page:1,
    loaded:false,
  }),
  watch:{
    balance(){
      if (this.balance) {
        this.balanceComptable=[]
        this.balancePrint=[]
        this.totalBalance={
          a_nouveau_debit:0,
          a_nouveau_credit:0,
          mouvement_debit:0,
          mouvement_credit:0,
          total_debit:0,
          total_credit:0,
          solde_debit:0,
          solde_credit:0,
        }
        if (this.classCompte=="" || this.classCompte==null) {
          this.balanceComptable = this.balance.donnees
        }else{
          var firstDigit=""
          this.balance.donnees.forEach(compte => {
            firstDigit=compte.numero_compte.split("")
            if (this.classCompte==firstDigit[0]) {
              this.balanceComptable.push(compte)
            }
          })
        }
        this.balanceComptable.forEach(compte => {
          this.totalBalance.a_nouveau_credit+=compte.a_nouveau_credit
          this.totalBalance.a_nouveau_debit+=compte.a_nouveau_debit
          this.totalBalance.mouvement_credit+=compte.mouvement_credit
          this.totalBalance.mouvement_debit+=compte.mouvement_debit
          this.totalBalance.total_credit+=compte.total_credit
          this.totalBalance.total_debit+=compte.total_debit
          this.totalBalance.solde_credit+=compte.solde_credit
          this.totalBalance.solde_debit+=compte.solde_debit
        })
        console.log("balanceComptable:")
        console.log(this.balanceComptable)
        this.long=this.balanceComptable.length
        this.nb_page=Math.round(this.long/26) 
        console.log(this.nb_page)
        if (this.nb_page<=1) {
          this.balancePrint.push(this.balanceComptable)
          // this.balancePrint[0].push()
        } else {
          for (let index = 0; index < this.nb_page; index++) {
            this.balancePrint.push([])
          }
          var count=0
          for (let i = 1; i < this.balancePrint.length; i++) {
            for (let j = count; j < (26*i); j++) {
              this.balancePrint[i-1].push(this.balanceComptable[j])
              count++
            }
          }
          if (this.balancePrint[this.balancePrint.length-1].length==0) {
            this.balancePrint.pop()
          }
        }
        console.log("balancePrint:")
        console.log(this.balancePrint)
        this.loaded=true        
      }
    }
  },
  created(){
    this.balanceData.base=this.year.base
    this.balanceData.consultation=this.year.consultation
    this.balanceData.annee_debut=this.year.annee_debut
    this.balanceData.annee_fin=this.year.annee_fin
    this.loadBalance(this.balanceData)
  },
  computed:{
    ...mapGetters(["balance","msgFailBalance"])
  },
  methods:{
    ...mapActions(["loadBalance"]),
    ...mapMutations(["setBalance","setFailBalance"]),
    search(){
      this.long=0
      this.loaded=false
      this.setBalance("")
      this.loadBalance(this.balanceData)
    },
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    searchClass(){
      this.long=0
      this.loaded=false
      this.setBalance("")
      this.loadBalance(this.balanceData)
    }
  },
})
</script>
