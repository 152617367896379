<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <th class="th-blue"
            style="width:20%">Compte</th>
        <th class="th-blue"
            style="width:60%">Libellé</th>
        <th class="th-blue text-right"
            style="width:20%">Montant</th>
      </tr>
    </thead>
    <tbody v-for="(peages,peskey) in peage"
           :key="peskey">
      <tr >
        <td scope="row"
            colspan="4"
            class="tht-blue text-center">{{ peages.titre }}</td>
      </tr>
      <tr v-for="(value,valkey) in peages.values" 
          :key="valkey">
        <td scope="row">{{ value.numero_compte }}</td>
        <td>{{ value.libelle }}</td>
        <td class="text-right">{{ value.montant.toLocaleString() }}</td>
      </tr>
    </tbody>
    
  </table>
</template>
<script>
// import { mapActions,mapGetters,mapMutations } from "vuex"

export default ({
  name:"Peage",
  props:["analytique"],
  data: ()=>({
    peage:[],
    load: false
  }),
  created(){
    this.load=false
    if (this.analytique) {
      this.analytique.forEach(element => {
        if (this.peage.length > 0) {
          if (!this.peage.includes(element.groupe)) {
            this.peage.push(element.groupe)
          }   
        }else{
          this.peage.push(element.groupe)
        }
      })
      for (let index = 0; index < this.peage.length; index++) {
        this.peage[index]= {titre:this.peage[index], values:[]}
      }
      this.peage.forEach(pes => {
        this.analytique.forEach(element => {
          if (pes.titre == element.groupe) {
            pes.values.push(element)
          }
        })
      })
      this.load = true
      console.log(this.peage)
    }
  },
  watch:{
    analytique(){
      this.load=false
      if (this.analytique) {
        this.analytique.forEach(element => {
          if (this.peage.length > 0) {
            if (!this.peage.includes(element.groupe)) {
              this.peage.push(element.groupe)
            }   
          }else{
            this.peage.push(element.groupe)
          }
        })
        for (let index = 0; index < this.peage.length; index++) {
          this.peage[index]= {titre:this.peage[index], values:[]}
        }
        this.peage.forEach(pes => {
          this.analytique.forEach(element => {
            if (pes.titre == element.groupe) {
              pes.values.push(element)
            }
          })
        })
        this.load = true
        console.log(this.peage)
      }
    }
  },
  computed:{

  },
  methods:{
    
  }
})
</script>
