<template>
  <table class="table"
         style="font-size: 0.6em;">
    <thead>
      <tr>
        <th style="width:50%;border:none"></th>
        <th style="border:none"
            class="th-blue text-center"
            colspan="2">Exercice clos le 31 Décembre</th>
      </tr>
      <tr>
        <th style="width:50%;border:none"></th>
        <th style="width:25%"
            class="th-blue text-center">{{annee[0]}}</th>
        <th style="width:25%"
            class="th-blue text-center">{{Number(annee[0])-1}}</th>
      </tr>
    </thead>
    <template v-for="(groupe,gkey) in total_groupe">
      <tbody :key="gkey">
        <tr>
          <td scope="row"><strong>{{groupe.libelle}}</strong></td>
          <td scope="row"
              class="text-right"><strong>{{(Number(total_groupe[gkey].brut_n) - Number(total_groupe[gkey].amort_n)).toLocaleString()}}</strong></td>
          <td scope="row"
              class="text-right"><strong>{{(Number(total_groupe[gkey].brut_n_1) - Number(total_groupe[gkey].amort_n_1)).toLocaleString()}}</strong></td>
        </tr>
        <template v-for="(result,reskey) in resultat.donnees">
          <tr :key="'sousgroupe'+reskey"
              v-if="groupe.libelle == result.etat_financier_groupes && result.preriode == 'n'">
            <td>{{result.libelle}}</td>
            <td class="text-right"
                v-if="result.preriode=='n'">
              {{Math.round(Number(result.montant)).toLocaleString()}}
            </td>
            <template v-for="(result_n1,res1key) in resultat.donnees">
              <td class="text-right"
                  :key="'annee_n-1'+res1key"
                  v-if="result_n1.preriode=='n-1' && result_n1.libelle == result.libelle">
                {{Math.round(Number(result_n1.montant)).toLocaleString()}}
              </td>
            </template>
          </tr>
        </template>
      </tbody>
    </template>
    <tfoot>
      <tr>
        <td class="th-blue">TOTAL</td>
        <td class="text-right th-blue">{{(Number(total.brut_n) - Number(total.amort_n)).toLocaleString()}}</td>
        <td class="text-right th-blue">{{(Number(total.brut_n_1) - Number(total.amort_n_1)).toLocaleString()}}</td>
      </tr>
    </tfoot>
    <!-- <tbody v-if="charged">
      <tr>
        <td scope="row"><strong>PRODUITS D'EXPLOITATION</strong></td>
        <td class="text-right"></td>
        <td class="text-right"></td>
      </tr>
      <tr>
        <td scope="row">Revenus</td>
        <td class="text-right">{{result["annee_n"].Revenus.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Revenus.toLocaleString()}}</td>
      </tr>
      <tr>
        <td scope="row">Autres produits d'exploitation</td>
        <td class="text-right">{{result["annee_n"].Autres_produits_exploitations.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Autres_produits_exploitations.toLocaleString()}}</td>
      </tr>
      <tr>
        <td scope="row">Production immobilisée</td>
        <td class="text-right">{{result["annee_n"].Production_immobilisees.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Production_immobilisees.toLocaleString()}}</td>
      </tr>
      <tr class="tht-dark-blue">
        <td scope="row"><strong>Total des produits d'exploitation</strong></td>
        <td class="text-right"><strong>{{result["annee_n"].Total_produits_exploitations.toLocaleString()}}</strong></td>
        <td class="text-right"><strong>{{result["annee_n-1"].Total_produits_exploitations.toLocaleString()}}</strong></td>
      </tr>
      <tr>
        <td scope="row"><strong>CHARGES D'EXPLOITATION</strong></td>
        <td class="text-right"></td>
        <td class="text-right"></td>
        
      </tr>
      <tr>
        <td scope="row">Variation des stocks des produits finis et des encours (en+ou-)</td>
        <td class="text-right">{{result["annee_n"].Variation_des_stocks.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Variation_des_stocks.toLocaleString()}}</td>
      </tr>
      <tr>
        <td scope="row">Achats de marchandises consommés</td>
        <td class="text-right">{{result["annee_n"].Achat_marchandises.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Achat_marchandises.toLocaleString()}}</td>
      </tr>
      <tr>
        <td scope="row">Charges de personnel</td>
        <td class="text-right">{{result["annee_n"].Charges_de_personnels.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Charges_de_personnels.toLocaleString()}}</td>
      </tr>
      <tr>
        <td scope="row">Dotations aux amortissements et aux provisions (non financières)</td>
        <td class="text-right">{{result["annee_n"].Dotations_aux_amortissements.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Dotations_aux_amortissements.toLocaleString()}}</td>
      </tr>
      <tr>
        <td scope="row">Autres charges d'exploitation</td>
        <td class="text-right">{{result["annee_n"].Autres_charges_exploitations.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Autres_charges_exploitations.toLocaleString()}}</td>
      </tr>
      <tr class="tht-dark-blue">
        <td scope="row"><strong>Total des charges d'exploitation</strong></td>
        <td class="text-right"><strong>{{result["annee_n"].Total_charges_exploitations.toLocaleString()}}</strong></td>
        <td class="text-right"><strong>{{result["annee_n-1"].Total_charges_exploitations.toLocaleString()}}</strong></td>
      </tr>
      <tr>
        <td scope="row">Résultat d'exploitation</td>
        <td class="text-right">{{result["annee_n"].Resultat_exploitations.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Resultat_exploitations.toLocaleString()}}</td>
      </tr>
      <tr>
        <td scope="row">Charges financières nettes</td>
        <td class="text-right">{{result["annee_n"].Charges_financieres_nettes.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Charges_financieres_nettes.toLocaleString()}}</td>
      </tr>
      <tr>
        <td scope="row">Produits des placements</td>
        <td class="text-right">{{result["annee_n"].Produits_des_placements.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Produits_des_placements.toLocaleString()}}</td>
      </tr>
      <tr>
        <td scope="row">Autres gains ordinaires</td>
        <td class="text-right">{{result["annee_n"].Autres_gains_ordinaires.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Autres_gains_ordinaires.toLocaleString()}}</td>
      </tr>
      <tr>
        <td scope="row">Autres pertes ordinaires</td>
        <td class="text-right">{{result["annee_n"].Autres_pertes_ordinaires.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Autres_pertes_ordinaires.toLocaleString()}}</td>
      </tr>
      <tr class="tht-dark-blue">
        <td scope="row"><strong>Résultat des activités ordinaire avant impôt</strong></td>
        <td class="text-right"><strong>{{result["annee_n"].Resultat_des_activites_ordinaires_avant_impots.toLocaleString()}}</strong></td>
        <td class="text-right"><strong>{{result["annee_n-1"].Resultat_des_activites_ordinaires_avant_impots.toLocaleString()}}</strong></td>
      </tr>
      <tr>
        <td scope="row">Impôt sur les bénéfices</td>
        <td class="text-right">{{result["annee_n"].Impot_sur_les_benefices.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Impot_sur_les_benefices.toLocaleString()}}</td>
      </tr>
      <tr class="tht-dark-blue">
        <td scope="row"><strong>Résultat des activités ordinaire après impôt</strong></td>
        <td class="text-right"><strong>{{result["annee_n"].Resultat_des_activites_ordinaires_apres_impots.toLocaleString()}}</strong></td>
        <td class="text-right"><strong>{{result["annee_n-1"].Resultat_des_activites_ordinaires_apres_impots.toLocaleString()}}</strong></td>
      </tr>
      <tr>
        <td scope="row">Eléments extraordinaires (Gains/Pertes)</td>
        <td class="text-right">{{result["annee_n"].Elements_extraordinaires.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Elements_extraordinaires.toLocaleString()}}</td>
      </tr>
      <tr class="tht-dark-blue">
        <td scope="row"><strong>Résultat net de l'exercice</strong></td>
        <td class="text-right"><strong>{{result["annee_n"].Resultat_nets.toLocaleString()}}</strong></td>
        <td class="text-right"><strong>{{result["annee_n-1"].Resultat_nets.toLocaleString()}}</strong></td>
      </tr>
      <tr>
        <td scope="row">Effet des modifications comptables (net d'impôt)</td>
        <td class="text-right">{{result["annee_n"].Effet_des_modifications.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Effet_des_modifications.toLocaleString()}}</td>
      </tr>
      <tr class="tht-dark-blue">
        <td scope="row"><strong>Résultat après modifications comptables</strong></td>
        <td class="text-right"><strong>{{result["annee_n"].Resultat_apres_modifications.toLocaleString()}}</strong></td>
        <td class="text-right"><strong>{{result["annee_n-1"].Resultat_apres_modifications.toLocaleString()}}</strong></td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr>
        <td scope="row"><strong>PRODUITS D'EXPLOITATION</strong></td>
        <td class="text-right"></td>
        <td class="text-right"></td>
      </tr>
      <tr>
        <td scope="row">Revenus</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr>
        <td scope="row">Autres produits d'exploitation</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr>
        <td scope="row">Production immobilisée</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr class="tht-dark-blue">
        <td scope="row"><strong>Total des produits d'exploitation</strong></td>
        <td class="text-right"><strong>0</strong></td>
        <td class="text-right"><strong>0</strong></td>
      </tr>
      <tr>
        <td scope="row"><strong>CHARGES D'EXPLOITATION</strong></td>
        <td class="text-right"></td>
        <td class="text-right"></td>
      </tr>
      <tr>
        <td scope="row">Variation des stocks des produits finis et des encours (en+ou-)</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr>
        <td scope="row">Achats de marchandises consommés</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr>
        <td scope="row">Charges de personnel</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr>
        <td scope="row">Dotations aux amortissements et aux provisions (non financières)</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr>
        <td scope="row">Autres charges d'exploitation</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr class="tht-dark-blue">
        <td scope="row"><strong>Total des charges d'exploitation</strong></td>
        <td class="text-right"><strong>0</strong></td>
        <td class="text-right"><strong>0</strong></td>
      </tr>
      <tr>
        <td scope="row">Résultat d'exploitation</td>
        <td class="text-right"><strong>0</strong></td>
        <td class="text-right"><strong>0</strong></td>
      </tr>
      <tr>
        <td scope="row">Charges financières nettes</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr>
        <td scope="row">Produits des placements</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr>
        <td scope="row">Autres gains ordinaires</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr>
        <td scope="row">Autres pertes ordinaires</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr class="tht-dark-blue">
        <td scope="row"><strong>Résultat des activités ordinaire avant impôt</strong></td>
        <td class="text-right"><strong>0</strong></td>
        <td class="text-right"><strong>0</strong></td>
      </tr>
      <tr>
        <td scope="row">Impôt sur les bénéfices</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr class="tht-dark-blue">
        <td scope="row"><strong>Résultat des activités ordinaire après impôt</strong></td>
        <td class="text-right"><strong>0</strong></td>
        <td class="text-right"><strong>0</strong></td>
      </tr>
      <tr>
        <td scope="row">Eléments extraordinaires (Gains/Pertes)</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr class="tht-dark-blue">
        <td scope="row"><strong>Résultat net de l'exercice</strong></td>
        <td class="text-right"><strong>0</strong></td>
        <td class="text-right"><strong>0</strong></td>
      </tr>
      <tr>
        <td scope="row">Effet des modifications comptables (net d'impôt)</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr class="tht-dark-blue">
        <td scope="row"><strong>Résultat après modifications comptables</strong></td>
        <td class="text-right"><strong>0</strong></td>
        <td class="text-right"><strong>0</strong></td>
      </tr>
    </tbody> -->
  </table>
</template>
<script>

import { mapActions,mapGetters,mapMutations } from "vuex"

export default ({
  name:"Resultats",
  props:["year"],
  data:()=>({
    resultatData:{
      annee_debut:"",
      annee_fin:"",
      base:""
    },
    annee:"",
    result:[],
    total:[],
    total_groupe:[],
    groupes:[],
    charged:false
  }),
  watch:{
    resultat(){
      if (this.resultat) {
        this.groupes=[]
        this.resultat.donnees.forEach(groupe => {
          var isIn=false
          for (let index = 0; index < this.groupes.length; index++) {
            if (this.groupes[index].groupe==groupe.etat_financier_groupes) {
              isIn=true
            }
          }
          if (!isIn) {
            this.groupes.push({groupe:groupe.etat_financier_groupes,type:groupe.statut_groupe})
          }
        })
        
        this.total={brut_n:0,brut_n_1:0,amort_n_1:0,amort_n:0}
        
        for(let index=0; index < this.groupes.length; index++){
          this.total_groupe.push({libelle:this.groupes[index].groupe,brut_n:0,amort_n:0,brut_n_1:0,amort_n_1:0})
          this.resultat.donnees.forEach(result => {
            if (result.etat_financier_groupes == this.total_groupe[index].libelle && result.preriode=='n') {
              if (result.statut_sous_groupe=="BRUTE") {
                this.total_groupe[index].brut_n += result.montant
                this.total.brut_n += result.montant
              }else{
                this.total_groupe[index].amort_n += result.montant
                this.total.amort_n += result.montant
              }
            }else{
              if (result.etat_financier_groupes==this.total_groupe[index].libelle && result.preriode=='n-1') {
                // console.log("in")
                if (result.statut_sous_groupe=="BRUTE") {
                  this.total_groupe[index].brut_n_1 += result.montant
                  this.total.brut_n_1 += result.montant
                }else{
                  this.total_groupe[index].amort_n_1 += result.montant
                  this.total.amort_n_1 += result.montant
                }
              } 
            }
          })
          
        }
        console.log(this.resultat)
        // this.setResultat("")
      }
    }
  },
  created(){
    this.result = []
    this.charged=false
    this.resultatData.annee_debut = this.year.annee_debut
    this.resultatData.annee_fin = this.year.annee_fin
    this.annee = this.year.annee_fin.split("-")
    this.resultatData.base=this.year.base
    this.loadResultat(this.resultatData)
  },
  computed:{
    ...mapGetters(["resultat","msgFailResultat"])
  },
  methods:{
    ...mapActions(["loadResultat",]),
    ...mapMutations(["setResultat","setFailResultat"]),

  }
})
</script>
